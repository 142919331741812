@import "src/index";

.not-support {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $background-overlay;
  z-index: 9999;
  color: $text-white;
  display: flex;
  justify-content: center;
  align-items: center;
}
