@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

// text
$text-primary: #333;
$text-secondary: #525252;
$text-gray-100: #A3A3A3;
$text-gray-200: #737373;
$text-white: #FFF;

// border
$border-primary: #EEEEEE;

// button
$button-primary: #2560E5;

// background
$background-grey: #F5F5F5;
$background-primary: #C92127;
$background-overlay: rgba(0, 0, 0, 0.8);

html, body {
  margin: 0;
  box-sizing: border-box;
  overflow-x: hidden;
}

p, h3 {
  margin: 0;
}

a {
  text-decoration: none;
}

@mixin text-sm {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px
}

@mixin text-base {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.ant-notification-notice-success {
  border-radius: 2px;
  border: 1px solid #A7F3D0;
  background: #ECFDF5;
  padding: 10px 16px !important;

  .ant-notification-notice-message {
    @include text-sm();
    margin-bottom: 0 !important;
    color: $text-primary !important;
  }
}
.ant-notification-notice-error  {
  border-radius: 2px;
  border: 1px solid #FECDD3;
  background: #FFF1F2;
  padding: 10px 16px !important;

  .ant-notification-notice-message {
    @include text-sm();
    margin-bottom: 0 !important;
    color: $text-primary !important;
  }
}
